var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page",attrs:{"id":"view_job_details"}},[_c('div',{staticClass:"pa-5 pt-md-10 pl-md-10"},[(_vm.clean_string(_vm.AppPortal) === _vm.clean_string(_vm.Portals.COREHCS))?_c('img',{attrs:{"src":require("@/assets/logos/corehcs.svg"),"alt":"CoreHCS"}}):(_vm.clean_string(_vm.AppPortal) === _vm.clean_string(_vm.Portals.COREHCMS))?_c('v-img',{attrs:{"width":"120px","src":require("@/assets/logos/core42-hcms.svg"),"alt":"Core-HCMS"}}):_c('img',{attrs:{"src":require("@/assets/logos/hcms.svg"),"alt":"HCMS"}})],1),_c('v-main',{staticClass:"content"},[(!_vm.loading)?[(!_vm.edit_job_details)?_c('section',{staticClass:"my-6 job-details d-flex flex-column"},[_c('JobTitleBlock',{attrs:{"title":_vm.job.job_title,"employment_type":null,"job_id":_vm.job.jid}}),_c('v-row',{staticClass:"extra-details"},[_c('v-col',[_c('div',{staticClass:"base-card extra-details-wrapper"},[_c('div',{staticClass:"details",class:{
                  'flex-grow-1':
                    !_vm.view_on_linkedin_allowed_states.includes(_vm.view_job_state)
                }},[(_vm.industries_allowed_states.includes(_vm.view_job_state))?_c('ExtraDetails',{attrs:{"title":_vm.$t('recruiter.view-job.industry-title'),"color":"#33BFFF","icon":_vm.industry_icon,"value":_vm.job.sector}}):_vm._e(),(_vm.track_allowed_states.includes(_vm.view_job_state))?_c('ExtraDetails',{attrs:{"title":_vm.$t('recruiter.view-job.track-title'),"color":"#FF8473","icon":_vm.track_icon,"value":"Software and Application"}}):_vm._e(),(_vm.experience_allowed_states.includes(_vm.view_job_state))?_c('ExtraDetails',{attrs:{"title":_vm.$t('recruiter.view-job.experience-title'),"color":"#3CE17E","icon":_vm.experience_icon,"value":_vm.job.experience
                      ? _vm.job.experience
                      : _vm.$t('recruiter.no-experience')}}):_vm._e()],1),(
                  _vm.view_on_linkedin_allowed_states.includes(_vm.view_job_state)
                )?_c('v-btn',{attrs:{"text":"","x-large":"","color":"primary","disabled":!_vm.job.url,"href":_vm.job.url,"target":"_blank"}},[_c('inline-svg',{staticClass:"mr-2",attrs:{"src":require('@/assets/logos/linkedin-3.svg')}}),_c('p',{staticClass:"text-capitalize mb-n1"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.view-job.linkedin-view"))+" ")])],1):_vm._e()],1)])],1),_c('v-row',[_c('v-col',[_c('JobDescriptionCard',{attrs:{"description":_vm.job.job_description}})],1)],1),_c('v-row',[_c('v-col',[_c('SkillsWithGroups',{attrs:{"skills":_vm.job.skills_list,"title":_vm.$t('recruiter.view-job.required-skills')}})],1)],1)],1):_vm._e()]:[_c('RootLoader')]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }