



















































































































import Vue from "vue";
import { ViewPublicJobDetails } from "@/interfaces/shared/view_job_details/view_public_job_details";
import ExtraDetails from "@/components/shared/view_job_details/ExtraDetails.vue";
import JobDescriptionCard from "@/components/shared/view_job_details/JobDescriptionCard.vue";
import SkillsWithGroups from "@/components/shared/SkillsWithGroups.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  COMMON_LOADING,
  FETCH_SKILLS_GROUP,
  GET_JOB_BY_KEY,
  VIEW_JOB_STATE
} from "@/store/modules/common/constants";
import router from "@/router";
import { GET_USER_DETAILS, USER_STATE } from "@/store/modules/auth/constants";
import JobTitleBlock from "@/components/shared/view_job_details/JobTitleBlock.vue";
import {
  ACTIVATE_PUBLISHED_JOB,
  FETCH_MATCHING_CANDIDATES,
  POST_JOB,
  PUSH_TOP_JOB_WITH_CANDIDATES,
  RECOMMEND_JOB_PROFILES,
  RECRUITER_LOADING,
  UPDATE_TOP_MATCHING_CANDIDATES
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { ViewJobState } from "@/store/modules/common/interfaces";
import RootLoader from "@/components/shared/RootLoader.vue";
import { UserState } from "@/store/modules/auth/interfaces";
import { IApp } from "@/interfaces";
import { clean_string } from "@/utils/global";

export default Vue.extend({
  name: "ViewJobDetails",
  components: {
    RootLoader,
    JobTitleBlock,
    SkillsWithGroups,
    JobDescriptionCard,
    ExtraDetails
  },
  data(): ViewPublicJobDetails {
    return {
      recommend_job_index: -1,
      previous_job: null,
      loading: true,
      edit_job_details: false,
      job: null, // For holding job data
      industry_icon: require("@/assets/icons/bold/devices.svg"),
      track_icon: require("@/assets/icons/bold/mobile.svg"),
      experience_icon: require("@/assets/icons/bold/brifecase-tick.svg"),
      view_on_linkedin_allowed_states: [
        ViewJobState.DEFAULT,
        ViewJobState.LINKEDIN_JOB_POST
      ],
      recommended_job_profile_allowed_states: [
        ViewJobState.LINKEDIN_JOB_POST,
        ViewJobState.TEXT_JOB_POST,
        ViewJobState.FILE_JOB_POST,
        ViewJobState.EDIT_TEXT_JOB_POST
      ],
      industries_allowed_states: [
        ViewJobState.DEFAULT,
        // ViewJobState.JOB_LISTING,
        ViewJobState.STANDARDISED,
        ViewJobState.STANDARDISED_JOB_POST,
        ViewJobState.LINKEDIN_JOB_POST,
        ViewJobState.TEXT_JOB_POST,
        ViewJobState.EDIT_TEXT_JOB_POST,
        ViewJobState.FILE_JOB_POST
      ],
      experience_allowed_states: [
        ViewJobState.DEFAULT,
        // ViewJobState.JOB_LISTING,
        ViewJobState.STANDARDISED,
        ViewJobState.STANDARDISED_JOB_POST,
        ViewJobState.LINKEDIN_JOB_POST,
        ViewJobState.TEXT_JOB_POST,
        ViewJobState.EDIT_TEXT_JOB_POST,
        ViewJobState.FILE_JOB_POST
      ],
      track_allowed_states: [
        ViewJobState.JOB_LISTING,
        ViewJobState.STANDARDISED,
        ViewJobState.STANDARDISED_JOB_POST,
        ViewJobState.TEXT_JOB_POST,
        ViewJobState.EDIT_TEXT_JOB_POST,
        ViewJobState.FILE_JOB_POST
      ],
      previous_view_job_state: ViewJobState.DEFAULT
    };
  },
  async created() {
    const job_key = this.$route.params.key;
    await this.init_data(job_key);
  },
  // Validating route param type is int on router enter
  beforeRouteEnter(to, from, next) {
    next();
  },
  // Validating route param type is int on router param update
  beforeRouteUpdate(to, from, next) {
    const param_key = to.params.key;
    this.set_view_job_state(ViewJobState.DEFAULT);
    this.init_data(param_key);
    next();
  },
  computed: {
    ViewJobState() {
      return ViewJobState;
    },
    AppPortal(): IApp.Portals {
      return process.env.VUE_APP_PORTAL;
    },
    Portals() {
      return IApp.Portals;
    },
    UserState() {
      return UserState;
    },
    ...mapGetters("recruiter", {
      recruiter_loading: RECRUITER_LOADING,
      get_recommend_job_profiles: RECOMMEND_JOB_PROFILES
    }),
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS,
      user_state: USER_STATE
    }),
    ...mapGetters("common", {
      common_loading: COMMON_LOADING,
      view_job_state: VIEW_JOB_STATE
    })
  },
  methods: {
    clean_string,
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("recruiter", {
      fetch_matching_candidates: FETCH_MATCHING_CANDIDATES,
      post_job: POST_JOB,
      activate_published_job: ACTIVATE_PUBLISHED_JOB
    }),
    ...mapMutations("recruiter", {
      push_top_job_with_candidates: PUSH_TOP_JOB_WITH_CANDIDATES,
      update_top_matching_candidates: UPDATE_TOP_MATCHING_CANDIDATES,
      set_recommend_job_profiles: RECOMMEND_JOB_PROFILES
    }),
    ...mapActions("common", {
      get_job_by_key: GET_JOB_BY_KEY,
      fetch_skill_group: FETCH_SKILLS_GROUP
    }),
    ...mapMutations("common", {
      set_view_job_state: VIEW_JOB_STATE
    }),
    /**
     * Function to initialize job data
     * @param job_id
     */
    async init_data(job_key: string) {
      this.loading = true;
      // Get job from DB
      const response = await this.get_job_by_key(job_key);
      if (!response) {
        await router.push({
          name: "recruiter-error"
        });
        this.loading = false;
      } else {
        // Checking url starts with https => if not append https in start of url
        if (response.url && !response.url.startsWith("http")) {
          response.url = "https://" + response.url;
        }
        // Parsing skills
        if (response.skills_list) {
          let skills: string | string[] = [];
          skills = JSON.parse(response.skills_list);
          if (typeof skills === "string") skills = eval(skills);
          response.skills_list = await this.fetch_skill_group(skills);
        }
        this.job = {
          ...response,
          matching_candidates: [],
          candidates_loading: true
        };
        this.loading = false;
      }
    }
  },
  destroyed() {
    this.set_view_job_state(ViewJobState.DEFAULT);
  }
});
